import React, { useRef, useEffect, createContext, ReactNode, useState } from 'react';

export type WindowDimensionsContextData = {
  size: number;
  height: number;
  isScrolled: boolean;
  isMobile: boolean;
  isTablet: boolean;
};

interface WindowDimensionsProviderProps {
  children: ReactNode
}

const WindowDimensionsContext = createContext<WindowDimensionsContextData>(
  {} as WindowDimensionsContextData
)

const WindowDimensionsProvider = ({ children }: WindowDimensionsProviderProps) => {
  const sizeRef = useRef<number>(0);
  const heightRef = useRef<number>(0);
  const [isScrolled, setIsScrolled] = useState(false)
  const [isMobile, setIsMobile] = useState(true)
  const [isTablet, setIsTablet] = useState(false)
  useEffect(() => {
    function handleResize() {
      sizeRef.current = window.innerWidth;
      if (sizeRef.current > 0 && sizeRef.current < 1014){
        setIsMobile(true)
        setIsTablet(false)
      }
      else if (sizeRef.current >= 1014 && sizeRef.current < 1368) {
        setIsTablet(true)
      }
      else {
        setIsMobile(false)
        setIsTablet(false)
      }

    }

    function handleScroll() {
      heightRef.current = window.pageYOffset;
      if (heightRef.current > 45){
        setIsScrolled(true)
      }
      else {
        setIsScrolled(false)
      }

    }
    handleResize();
    handleScroll();

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const value = {
    size: sizeRef.current,
    height: heightRef.current,
    isScrolled,
    isMobile,
    isTablet
  };

  return (
    <WindowDimensionsContext.Provider value={value}>
      {children}
    </WindowDimensionsContext.Provider>
  )
}

export { WindowDimensionsProvider, WindowDimensionsContext }
