import React from "react"
import { AppProvider } from "./src/contexts"
import "./src/assets/scss/main.scss"
import "@fontsource/open-sans/400-italic.css"
import "@fontsource/open-sans/400.css"
import "@fontsource/open-sans/600-italic.css"
import "@fontsource/open-sans/600.css"
import "@fontsource/open-sans/700-italic.css"
import "@fontsource/open-sans/700.css"
import "@fontsource/barlow/500-italic.css"
import "@fontsource/barlow/500.css"
import "@fontsource/barlow/600-italic.css"
import "@fontsource/barlow/600.css"
import "@fontsource/barlow/700-italic.css"
import "@fontsource/barlow/700.css"

export const wrapRootElement = ({ element }) => {
  return (
    <AppProvider>
      {element}
    </AppProvider>
  )
}
