import React, {useMemo} from "react"
import { createContext } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GraphQLContextData, NavigationSettingsContextData, NavigationSettingsProviderProps} from "./navigation-types"
import { getFormattedNavigation } from "./getFormattedNavigation"

const NavigationSettingsContext = createContext<NavigationSettingsContextData>({} as NavigationSettingsContextData)

const NavigationSettingsProvider = ({ children }: NavigationSettingsProviderProps) => {
  const data = useStaticQuery<{ kontentItemSettings: GraphQLContextData }>(getNavigationSettingsData)

  const { kontentItemSettings } = data

  const {
    header_navigation,
    footer_navigation,
    secondary_header_navigation,
    side_navigation,
    credits_navigation
  } = kontentItemSettings?.elements!

  const headerNavigation = useMemo(() => {
    const headerNavItems = header_navigation?.modular_content
    if (!headerNavItems || header_navigation.modular_content.length === 0 ) return []
    const formattedHeaderNavItems = getFormattedNavigation(headerNavItems)
    return formattedHeaderNavItems
  }, [header_navigation]) 

  const footerNavigation = useMemo(() => {
    const footerNavItems = footer_navigation?.modular_content
    if (!footerNavItems || footer_navigation.modular_content.length === 0 ) return []
    const formattedFooterNavItems = getFormattedNavigation(footerNavItems)
    return formattedFooterNavItems
  }, [footer_navigation]) 

  const secondaryHeaderNavigation = useMemo(() => {
    const secondaryHeaderNavItems = secondary_header_navigation?.modular_content
    if (!secondaryHeaderNavItems || secondary_header_navigation.modular_content.length === 0 ) return []
    const formattedSecondaryHeaderNavItems = getFormattedNavigation(secondaryHeaderNavItems)
    return formattedSecondaryHeaderNavItems
  }, [secondary_header_navigation]) 

  const sideNavigation = useMemo(() => {
    const sideNavItems = side_navigation?.modular_content
    if (!sideNavItems || side_navigation.modular_content.length === 0 ) return []
    const formattedSideNavItems = getFormattedNavigation(sideNavItems)
    return formattedSideNavItems
  }, [side_navigation]) 

  const creditsNavigation = useMemo(() => {
    const creditsNavItems = credits_navigation?.modular_content
    if (!creditsNavItems || credits_navigation.modular_content.length === 0 ) return []
    const formattedCreditsNavItems = getFormattedNavigation(creditsNavItems)
    return formattedCreditsNavItems
  }, [credits_navigation]) 

  return (
    <NavigationSettingsContext.Provider
      value={{
        headerNavigation,
        footerNavigation,
        secondaryHeaderNavigation,
        sideNavigation,
        creditsNavigation
      }}
    >
      {children}
    </NavigationSettingsContext.Provider>
  )
}

export const getNavigationSettingsData = graphql`
  query NavigationSettingsContext {
    kontentItemSettings {
      elements {
        header_navigation {
          modular_content {
            ...NavigationFragment
          }
        }
        secondary_header_navigation {
          modular_content {
            ...NavigationFragment
          }
        }
        side_navigation {
          modular_content {
            ...NavigationFragment
          }
        }
        footer_navigation {
          modular_content {
            ...NavigationFragment
          }
        }
        credits_navigation {
          modular_content {
            ...NavigationFragment
          }
        }
      }
    }
  }
`

export { NavigationSettingsProvider, NavigationSettingsContext }

export const query = graphql`
fragment NavigationFragment on kontent_item_navigation_item {
  id
  elements {
    link_text {
      value
    }
    external_link {
      value
    }
    page {
      value {
        ...PageSlugFragment
      }
    }
    child_page {
      value {
        ...NavigationItemWithoutChildFragment
      }
    }
  }
}
`

export const navigationItemFragment = graphql`
fragment NavigationItemWithoutChildFragment on kontent_item_navigation_item {
  id
  elements {
    link_text {
      value
    }
    external_link {
      value
    }
    page {
      value {
        ...PageSlugFragment
      }
    }
  }
}
`

export const pageSlugFragment = graphql`
fragment PageSlugFragment on kontent_item_page {
  id
  elements {
    slug {
      value
    }
  }
}
`