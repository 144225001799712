module.exports = [{
      plugin: require('../../gatsby-theme-abraham/node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Abraham POOL","short_name":"Abraham POOL","start_url":"/","theme_color":"#fff","background_color":"#fff","display":"standalone","icon":"../site/src/images/lobster512.png","include_favicon":false,"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"27482c17a3107125cb2f6533b43bbae5"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://abraham-pool.fieldroutesthemes.com","noQueryString":true},
    },{
      plugin: require('../../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Home","exclude":["/dev-404-page/","/404/","/404.html","/offline-plugin-app-shell-fallback/"],"crumbLabelUpdates":[{"pathname":"/blog","crumbLabel":"Blog"},{"pathname":"/where-we-service","crumbLabel":"Where We Service"}]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-tagmanager-timeout/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PHR5M4C","includeInDevelopment":false,"timeout":5000,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"page-change"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-200},
    },{
      plugin: require('../../gatsby-theme-abraham/gatsby-browser.js'),
      options: {"plugins":[],"projectId":"d0cade8e-51c5-005f-ca69-681f2c83b670","authorizationKey":"ew0KICAiYWxnIjogIkhTMjU2IiwNCiAgInR5cCI6ICJKV1QiDQp9.ew0KICAianRpIjogImY0ZWM3NTlhYzc0ODRkNTA5MGUxZWM2NjFjODQ2ZTVhIiwNCiAgImlhdCI6ICIxNjU0MTgxMzYxIiwNCiAgImV4cCI6ICIxOTk5NzgxMzYxIiwNCiAgInZlciI6ICIxLjAuMCIsDQogICJwcm9qZWN0X2lkIjogImQwY2FkZThlNTFjNTAwNWZjYTY5NjgxZjJjODNiNjcwIiwNCiAgImF1ZCI6ICJwcmV2aWV3LmRlbGl2ZXIua2VudGljb2Nsb3VkLmNvbSINCn0.XIJ6FumRvlqXNsOfL54TD9xy7DFYCZXZbWfbg3WuWhY","usePreviewUrl":"false","siteUrl":"https://abraham-pool.fieldroutesthemes.com","title":"Abraham POOL","description":"Demo - Abraham - POOL (Baxter seed) - V2 - GC","domainVerification":"meta-tag","gtmId":"GTM-PHR5M4C","whereWeServicePageSlug":"where-we-service","whereWeServiceBreadcrumbLabel":"Where We Service"},
    }]
